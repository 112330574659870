import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID
};

class FirebaseManager {
  static firebaseInstance = null;

  static userId = null;
  static isSignedIn = false;
  static dataLoaded = false;
  
  static completedActionItems = [];
  static completedArticleProblems = [];

  static subscribers = [];

  static initializeFirebase() {
    if (typeof window !== 'undefined') {
      if (this.firebaseInstance || firebase.apps.length !== 0)
        return;
      this.firebaseInstance = firebase.initializeApp(firebaseConfig);
      this.dataUpdated();
    }
  }

  static getFirebase() {
    this.initializeFirebase();
    return this.firebaseInstance;
  }

  static getAuth() {
    let firebaseInstance = this.getFirebase();
    if (!firebaseInstance)
      return null;
    return firebaseInstance.auth();
  }

  static getLoginUiConfig() {
    this.initializeFirebase();
    return {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // Google and Email as auth providers.
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
      }
    };
  }

  static loadData() {
    this.initializeFirebase();
    this.dataLoaded = false;
    firebase.firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .get().then((doc) => {
        this.completedActionItems = doc.data()?.completedActionItems || [];
        this.completedArticleProblems = doc.data()?.completedArticleProblems || [];
        this.dataLoaded = true;
        this.dataUpdated();
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  static subscribe(fun) {
    this.subscribers.push(fun);
  }

  static unsubscribe(fun) {
    let index = this.subscribers.indexOf(fun);
    if (index > -1)
      this.subscribers.splice(index, 1);
  }

  static dataUpdated() {
    this.subscribers.forEach((f) => {f()});
  }

  static tryLogIn() {
    if (this.userId)
      return;
    this.logIn();
  }

  static logIn() {
    this.initializeFirebase();
    if (this.userId)
      return;
    this.userId = firebase.auth().currentUser.uid;
    this.isSignedIn = true;
    this.loadData();
  }

  static logOut() {
    this.userId = null;
    this.isSignedIn = false;
    this.dataLoaded = false;
    this.completedActionItems = [];
    this.completedArticleProblems = [];
    this.dataUpdated();
  }

  static getCompletedActionItems() {
    return this.completedActionItems;
  }

  static getCompletedArticleProblems() {
    return this.completedArticleProblems;
  }

  static toggleActionItem(actionItemId) {
    let docRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);
    let elementIndex = this.completedActionItems.indexOf(actionItemId);
    if (elementIndex > -1) {
      this.completedActionItems.splice(elementIndex, 1);
      this.dataUpdated();
      docRef.set({
        completedActionItems: firebase.firestore.FieldValue.arrayRemove(actionItemId)
      }, { merge: true })
      .then(() => {
        // this.completedActionItems.splice(elementIndex, 1);
        // this.dataUpdated();
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
    } else {
      this.completedActionItems.push(actionItemId);
      this.dataUpdated();
      docRef.set({
        completedActionItems: firebase.firestore.FieldValue.arrayUnion(actionItemId)
      }, { merge: true })
      .then(() => {
        // this.completedActionItems.push(actionItemId);
        // this.dataUpdated();
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
    }
  }

  static toggleArticleProblem(articleProblemId) {
    let docRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);
    let elementIndex = this.completedArticleProblems.indexOf(articleProblemId);
    if (elementIndex > -1) {
      this.completedArticleProblems.splice(elementIndex, 1);
      this.dataUpdated();
      docRef.set({
        completedArticleProblems: firebase.firestore.FieldValue.arrayRemove(articleProblemId)
      }, { merge: true })
      .then(() => {
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
    } else {
      this.completedArticleProblems.push(articleProblemId);
      this.dataUpdated();
      docRef.set({
        completedArticleProblems: firebase.firestore.FieldValue.arrayUnion(articleProblemId)
      }, { merge: true })
      .then(() => {
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
    }
  }
}

export default FirebaseManager;
