export default [
  {
    article: 'simple',
    problems: ['simple-412', 'simple-58'],
  },
  {
    article: 'loops',
    problems: ['loops-414', 'loops-344', 'loops-125', 'loops-1281'],
  },
  {
    article: 'arrays',
    problems: ['arrays-303', 'arrays-304'],
  },
  {
    article: 'sorting',
    problems: ['sorting-242', 'sorting-56'],
  },
  {
    article: 'setsmaps',
    problems: ['setsmaps-217', 'setsmaps-1', 'setsmaps-349', 'setsmaps-49'],
  },
  {
    article: 'stack',
    problems: ['stack-20', 'stack-844'],
  },
  {
    article: 'linkedlist',
    problems: ['linkedlist-237', 'linkedlist-206', 'linkedlist-21', 'linkedlist-141', 'linkedlist-86'],
  },
  {
    article: 'priorityqueue',
    problems: ['priorityqueue-215', 'priorityqueue-295'],
  },
  {
    article: 'strings',
    problems: ['strings-151', 'strings-482', 'strings-8', 'strings-273'],
  },
  {
    article: 'twopointers',
    problems: ['twopointers-125', 'twopointers-345', 'twopointers-167', 'twopointers-27', 'twopointers-283', 'twopointers-350', 'twopointers-228'],
  },
  {
    article: 'backtracking',
    problems: ['backtracking-78', 'backtracking-22', 'backtracking-46', 'backtracking-79'],
  },
  {
    article: 'math',
    problems: ['math-504', 'math-50', 'math-204', 'math-650'],
  },
  {
    article: 'binarysearch',
    problems: ['binarysearch-374', 'binarysearch-34', 'binarysearch-278', 'binarysearch-69', 'binarysearch-287'],
  },
  {
    article: 'bits',
    problems: ['bits-461', 'bits-136', 'bits-78'],
  },
  {
    article: 'dfs',
    problems: ['dfs-841', 'dfs-547', 'dfs-200', 'dfs-207'],
  },
  {
    article: 'bfs',
    problems: ['bfs-127', 'bfs-542'],
  },
  {
    article: 'binarytrees',
    problems: ['binarytrees-226', 'binarytrees-101', 'binarytrees-144', 'binarytrees-94', 'binarytrees-145', 'binarytrees-98', 'binarytrees-230', 'binarytrees-450', 'binarytrees-104', 'binarytrees-563', 'binarytrees-144-2', 'binarytrees-94-2', 'binarytrees-145-2', 'binarytrees-116', 'binarytrees-297'],
  },
  {
    article: 'dp',
    problems: ['dp-70', 'dp-746', 'dp-62', 'dp-64', 'dp-300', 'dp-322', 'dp-518', 'dp-416', 'dp-329'],
  },
  {
    article: 'trie',
    problems: ['trie-208', 'trie-720', 'trie-677', 'trie-212'],
  },
]
