import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import ArticlesSidebar from './ArticlesSidebar';

import FirebaseManager from '../firebase/firebaseManager.js';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
      loginModalOpen: false,
      isSignedIn: false,
    };
  }

  toggleMobileMenu = () => {
    this.setState({mobileMenuOpen: !this.state.mobileMenuOpen});
  }

  toogleLoginModal = () => {
    this.setState({loginModalOpen: !this.state.loginModalOpen});
  }

  componentDidMount() {
    this.unregisterAuthObserver = FirebaseManager.getAuth().onAuthStateChanged((user) => {
      if (user) {
        FirebaseManager.tryLogIn();
        this.setState({isSignedIn: true});
      } else {
        FirebaseManager.logOut();
        this.setState({isSignedIn: false});
      }
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    return (
      <div className="flex fixed top-0 inset-x-0 z-20 bg-white h-16 border-b-2 border-gray-100">
        <div className="w-full bg-white mx-auto px-4 sm:px-6">
          <div className="flex py-5 justify-start">
            {/* <img className="-mt-2 h-10 w-10 mr-3 opacity-75" src="/wreath.svg"/> */}
            <div>
              <Link to="/" className="flex font-semibold text-gray-700">
                Interviews<span className="text-blue-500">.school</span>
                {/* {this.props.siteTitle} */}
              </Link>
            </div>
            <nav className="ml-6 md:ml-8 flex space-x-6 md:space-x-8">
              {/* <div className="relative">
                <button type="button" className="text-gray-500 group inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                  <Link to="/">Home</Link>
                </button>
              </div> */}
              <Link to="/about" className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                About
              </Link>
              {/* <a href="https://ko-fi.com/interviewsschool" target='_blank' rel="nofollow noopener noreferrer" className="hidden sm:block text-base leading-6 font-medium text-orange-400 hover:text-yellow-500 focus:outline-none focus:text-yellow-500 transition ease-in-out duration-150">
                Support
              </a> */}
            </nav>
            <nav className="ml-auto mr-3 flex space-x-4 md:space-x-4">
              { !this.state.isSignedIn ?
                <button onClick={this.toogleLoginModal} className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none transition ease-in-out duration-150">
                  Log in
                </button>
                :
                <>
                  <div className="hidden md:block text-base leading-6 font-medium text-gray-500">
                    {FirebaseManager.getAuth().currentUser?.displayName}
                  </div>
                  <div>
                    <button onClick={() => FirebaseManager.getAuth().signOut()} className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                      Log out
                    </button>
                  </div>
                </>
              }
              <div className="hidden md:flex text-gray-500 pl-3 space-x-5" style={{marginTop: '2px'}}>
                <a href="https://t.me/interviewsschool" target="_blank" rel="nofollow noopener noreferrer">
                  <svg class="fill-current w-5 h-5" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.3763847,0.715862633 L0.657316069,7.58408686 C-0.055723353,7.90390989 -0.296898352,8.54445419 0.484943384,8.89205895 L5.03068631,10.3441163 L16.0216017,3.51640121 C16.6217013,3.08775321 17.2360953,3.20206181 16.7074354,3.67356793 L7.26770364,12.2647747 L6.97116516,15.9005651 C7.24582814,16.4619562 7.74872683,16.4645454 8.06949519,16.1855373 L10.6811472,13.7015864 L15.1540014,17.0682547 C16.1928401,17.6864776 16.7581126,17.2875139 16.9816217,16.1544451 L19.9154327,2.19078227 C20.2200387,0.796019408 19.7005663,0.181489627 18.3763847,0.715862633 Z"></path>
                  </svg>
                </a>
                <a href="https://twitter.com/InterviewsSch" target="_blank" rel="nofollow noopener noreferrer">
                  <svg class="fill-current w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M6.29 18.25c7.55 0 11.67-6.25 11.67-11.67v-.53c.8-.59 1.49-1.3 2.04-2.13-.75.33-1.54.55-2.36.65a4.12 4.12 0 0 0 1.8-2.27c-.8.48-1.68.81-2.6 1a4.1 4.1 0 0 0-7 3.74 11.65 11.65 0 0 1-8.45-4.3 4.1 4.1 0 0 0 1.27 5.49C2.01 8.2 1.37 8.03.8 7.7v.05a4.1 4.1 0 0 0 3.3 4.03 4.1 4.1 0 0 1-1.86.07 4.1 4.1 0 0 0 3.83 2.85A8.23 8.23 0 0 1 0 16.4a11.62 11.62 0 0 0 6.29 1.84"></path></svg>
                </a>
              </div>
            </nav>
            <div className="-my-2 md:hidden">
              <button onClick={this.toggleMobileMenu} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className={(this.state.loginModalOpen && !this.state.isSignedIn ? 'opacity-100 ' : 'translate-x-full opacity-0 ') + "fixed right-0 mt-12 z-10 px-2 w-screen md:max-w-md transform origin-top-right transition-opacity duration-120 ease-in-out"}>
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xs overflow-hidden">
              <div className="z-20 relative bg-white px-8 py-8 sm:gap-8 sm:p-8">
                <div className="fixed right-0 -mt-6 mr-5">
                  <div className="ml-auto">
                    <button onClick={this.toogleLoginModal} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex w-full">
                  <svg className="flex-shrink-0 ml-3 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                  </svg>
                  <div className="ml-3 space-y-1">
                    <p className="text-base leading-6 font-medium text-gray-700">
                      Log in
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      Log in or create an account.
                    </p>
                  </div>
                </div>
                <div className="pt-3 md:-mb-3">
                  { !this.state.isSignedIn &&
                    <StyledFirebaseAuth
                      uiConfig={FirebaseManager.getLoginUiConfig()}
                      firebaseAuth={FirebaseManager.getAuth()}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--
          Mobile menu, show/hide based on mobile menu state.

          Entering: "duration-200 ease-out"
            From: "opacity-0 scale-95"
            To: "opacity-100 scale-100"
          Leaving: "duration-100 ease-in"
            From: "opacity-100 scale-100"
            To: "opacity-0 scale-95"
        --> */}
        <div className={(this.state.mobileMenuOpen ? 'opacity-100 ' : 'translate-y-full opacity-0 ') + "z-10 fixed h-screen overflow-scroll overscroll-contain top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden transition-opacity duration-150 ease-in-out"}>
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xs bg-white">
              <div className="pt-5 pb-6 px-5 space-y-6">
                <div className="flex items-center justify-between">
                  <div className="ml-auto -mr-2">
                    <button onClick={this.toggleMobileMenu} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="ml-4 pb-4">
                <ArticlesSidebar onSelect={this.toggleMobileMenu} selectedArticle={this.props.selectedArticle} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
