import React from "react";
import { Link } from "gatsby"

export default [
  {
    article: 'intro',
    actionItems: [
      { id: 'intro-read',
        title: 'I have read the introduction...',
        description: "...and I am ready to read on!"
      },
      { id: 'intro-action-items',
        title: 'I know what action items are.',
      },
      { id: 'intro-navigation',
        title: 'I know how to get to the next section.',
        description: <>
          You can click <i>Next Article</i> link below, or use the menu on the left (or at the top-right on mobile devices).
        </>
      },
    ]
  },
  {
    article: 'format',
    actionItems: [
      { id: 'format-quiz',
        title: 'Answer the following questions:',
        description: <>
          <ul>
            <li>What are the four stages of interviewing?</li>
            <li>What are online and onsite interviews? What is the difference?</li>
            <li>Why passing interviews with several companies at the same time makes sense? What is negotiation?</li>
          </ul>
        </>
      },
      { id: 'format-companies',
        title: 'Come up with a list of companies you want to apply to.',
        description: <>
          We will talk more about applying later, but now is a good time to think where you would want to apply <i>if you couldn't fail</i>. Aim high. List the best companies in the world where you would want to work. List 5-10 companies and think why you would want to work there.
        </>
      },
    ]
  },
  {
    article: 'types',
    actionItems: [
      { id: 'types-quiz',
        title: 'Answer the following question:',
        description: <>
          What are the coding, behavioural and system design interviews? What interviewers are looking for at each of them?
        </>
      },
      { id: 'types-friend',
        title: 'Reach out to a friend who interviewed with some big company, and ask how their interview went.',
        description: <>
          To better understand the interviewing process, it's often a good idea to simply ask somebody who already went through it. As a bonus, you will also practice the skill of reaching out to people and asking questions – essential in the interview preparation!
          <br/><br/>
          If you don't know anybody with the interview experience, look for the online communities where you can ask this, or simply search for the interview experience videos on YouTube – there is a lot of material online.
        </>
      },
    ]
  },
  {
    article: 'preparation',
    actionItems: [
      { id: 'preparation-quiz',
        title: 'Answer the following questions:',
        description: <>
          <ul>
            <li>How to prepare for coding interviews?</li>
            <li>How to prepare for behavioural interviews?</li>
            <li>How to prepare for system design interviews?</li>
            <li>What else should you do?</li>
            <li>Which interview type you should prepare for most and why? What should you do?</li>
          </ul>
        </>
      },
      { id: 'preparation-leetcode',
        title: 'Try solving a coding problem on Leetcode.',
        description: <>
          Solving practice coding problems is really important, so go and try to solve some simple problem on <a href="https://leetcode.com" target='_blank' rel="nofollow noopener noreferrer">Leetcode</a>. For a good and simple first problem, you can choose <a href="https://leetcode.com/problems/fizz-buzz" target='_blank' rel="nofollow noopener noreferrer">Fizz Buzz</a>. Use any language you want, and don't worry if it seems hard – we will practice more later!
          <br/><br/>
          If you want to try some more simple problems (with solutions!), read <Link to="/simple">Simple Problems</Link> section. We will also cover Leetcode and coding problems in much more detail later.
        </>
      },
      { id: 'preparation-book',
        title: 'Try reading some programming book or an article.',
        description: <>
          Reading a good programming book is a great way to get a deeper understanding of software engineering, and it's also helpful in preparing for the interviews. You may start reading some programming book that you already have, or go to the <Link to="/books">Books</Link> section, choose a book you like, and start reading it.
          <br/><br/>
          Alternatively, you may watch some good software engineering talk (like <a href="https://youtu.be/PE4gwstWhmc" target='_blank' rel="nofollow noopener noreferrer">How We've Scaled Dropbox</a>) or read a good article (for example, <a href="https://robertheaton.com/2014/03/27/how-does-https-actually-work/" target='_blank' rel="nofollow noopener noreferrer">How does HTTPS actually work?</a>). The goal here is not to learn a lot right away, but to start getting into the habit of learning more about software engineering every day.
        </>
      },
    ]
  },
  {
    article: 'timeline',
    actionItems: [
      { id: 'timeline-preparation',
        title: 'Estimate how much time you need to prepare.',
        description: <>
          Similarly to how we did in the article, estimate how much time you need to prepare for the interviews. Consider the following:
          <ul>
            <li>Did you prepare or solved problems before?</li>
            <li>How well do you know algorithms? (Test question: do you know DFS, BFS, binary search, dynamic programming, and linked lists?)</li>
            <li>What is your software engineering background?</li>
            <li>How much free time do you have?</li>
          </ul>
          Try to be on the more conservative side, as things often take more time than you think. For most people, a reasonable estimate will lie somewhere in a 3 to 12 months range.
        </>
      },
      { id: 'timeline-personal',
        title: 'Create your personal timeline.',
        description: <>
          Create a personal timeline of when you want to start preparing and for how long, when you want to apply, and when you would want to start a new job. Again, be conservative and remember that it will take a long time. Your timeline may look something like this:
          <ul>
            <li>Now: start preparing.</li>
            <li>In 3 months: start applying.</li>
            <li>In 4 months: pass interviews.</li>
            <li>In 8-12 months, if everything goes well: move abroad and start a new job.</li>
          </ul>
          Setting such deadlines may be a great way to motivate yourself to actually prepare and apply. You may miss your deadlines, but it's better than not having any deadlines at all.
        </>
      },
    ]
  },
  {
    article: 'future',
    actionItems: [
      { id: 'future-internships',
        title: 'Check out the internship requirements and deadlines.',
        description: <>
          If you are a college student, check out the internship requirements and deadlines at some big companies. You may use the following websites:
          <ul>
            <li>
              <a href="https://careers.google.com/students/engineering-and-technical-internships/" target='_blank' rel="nofollow noopener noreferrer">Engineering & Technical Internships – Google Careers</a>
            </li>
            <li>
              <a href="https://www.facebook.com/careers/students-and-grads/" target='_blank' rel="nofollow noopener noreferrer">Students & Grads - Facebook Careers</a>
            </li>
            <li>
              <a href="https://www.amazon.jobs/en/teams/internships-for-students" target='_blank' rel="nofollow noopener noreferrer">Internships for students | Amazon.jobs</a>
            </li>
          </ul>
          You often need to apply to the internships very early. Don't miss the deadlines!
        </>
      },
      { id: 'future-cp',
        title: 'Try solving competitive programming problems.',
        description: <>
          Try solving 1-3 easy competitive programming problems. They are similar to the interview questions, but also have some differences. For example, you can try the following easy problems:
          <ol>
            <li>
              <a href="https://acm.timus.ru/problem.aspx?space=1&num=1409&locale=en" target='_blank' rel="nofollow noopener noreferrer">1409. Two Gangsters</a>
            </li>
            <li>
              <a href="https://acm.timus.ru/problem.aspx?space=1&num=1225" target='_blank' rel="nofollow noopener noreferrer">1225. Flags</a>
            </li>
            <li>
              <a href="https://acm.timus.ru/problem.aspx?space=1&num=1837&locale=en" target='_blank' rel="nofollow noopener noreferrer">1837. Isenbaev's Number</a>
            </li>
          </ol>
        </>
      },
      { id: 'future-longterm',
        title: 'Think about what else you can do to grow long term.',
        description: <>
          Think about what else you can do to grow and be a better software engineer long term. Here are some possible ideas for the inspiration:
          <ul>
            <li>
              Take an <a href="https://www.coursera.org/learn/algorithms-part1" target='_blank' rel="nofollow noopener noreferrer">algorithm</a> or <a href="https://www.coursera.org/learn/machine-learning" target='_blank' rel="nofollow noopener noreferrer">machine learning</a> course on Coursera.
            </li>
            <li>
              Read some good software engineering <Link to="/books">book</Link>.
            </li>
            <li>
              Build a small <a href="https://www.codementor.io/@npostolovski/40-side-project-ideas-for-software-engineers-g8xckyxef" target='_blank' rel="nofollow noopener noreferrer">side project</a>.
            </li>
          </ul>
        </>
      },
    ]
  },
  {
    article: 'motivation',
    actionItems: [
      { id: 'motivation-system',
        title: 'Try some system for organizing and tracking your interview preparation.',
        description: <>
          Some system for organizing your interview preparation – logging your everyday preparation, setting tasks and goals – can help a lot to stay motivated and focused. It doesn't really matter what system you use – any system is usually better than none at all.
          <br/><br/>
          Try starting to use some system, and outline some future goals and tasks there. For example, you may try one of the following apps and see what works for you: <a href="https://www.notion.so/" target='_blank' rel="nofollow noopener noreferrer">Notion</a>, <a href="http://trello.com/" target='_blank' rel="nofollow noopener noreferrer">Trello</a>, or <a href="http://happenapps.com/" target='_blank' rel="nofollow noopener noreferrer">Quiver</a>.
        </>
      },
    ]
  },
  {
    article: 'applying',
    actionItems: [
      { id: 'applying-companies',
        title: 'Create a list of the companies that you plan to apply to.',
        description: <>
          Create a list of the companies that you plan to apply to. Include top tech companies, but also try to include several startups and smaller companies that are interesting to you.
          <br/><br/>
          Many people also create some kind of a list or a spreadsheet where they track the companies they apply to and the current status of the process. You can also try this and see how it works for you!
        </>
      },
      { id: 'applying-referrals',
        title: 'Think about how to get referrals.',
        description: <>
          <Link to="/applying/#referrals">Referrals</Link> are the best way to apply to the companies, so it's important to get them for all the companies you want to apply to. Think who can help you with referrals (friends, friends of friends?), and start contacting them.
        </>
      },
      { id: 'applying-platforms',
        title: 'Try using LinkedIn and AngelList to apply to more companies.',
        description: <>
          <a href="https://www.linkedin.com/" target='_blank' rel="nofollow noopener noreferrer">LinkedIn</a> and <a href="http://angel.co/" target='_blank' rel="nofollow noopener noreferrer">AngelList</a> can be very helpful in applying to the companies – you can find a lot of interesting companies and startups you didn't even know about before.
          <br/><br/>
          Fill out your profile on both websites and start browsing for interesting opportunities. Often you will also need to have an up-to-date resume, which we will cover in detail in <Link to="/resume">the next section</Link>.
        </>
      },
    ]
  },
  {
    article: 'resume',
    actionItems: [
      { id: 'resume-template',
        title: "Choose your resume template.",
        description: <>
          Choose the template you like. You can use the <a href="https://bit.ly/is-resume-template" target='_blank' rel="nofollow noopener noreferrer">template from the article</a>, or pick a template in <a href="https://docs.google.com/" target='_blank' rel="nofollow noopener noreferrer">Google Docs</a> or <a href="https://www.overleaf.com/latex/templates/tagged/cv" target='_blank' rel="nofollow noopener noreferrer">Overleaf</a>.
        </>
      },
      { id: 'resume-create',
        title: "Create your resume.",
        description: <>
          Try to follow the advice from the article.
        </>
      },
      {
        id: 'resume-share',
        title: "Share your resume with 1-3 friends and ask them for the feedback.",
        description: "Having somebody check your resume is a great way to improve it and to avoid errors."
      }
    ]
  },
  {
    article: 'immigration',
    actionItems: [
      { id: 'immigration-research',
        title: "Research the countries you are interested in.",
        description: <>
          Even if you are not looking to relocate right now, it can be interesting and useful to research how it is to live in some countries you are interested in. <a href="https://wikitravel.org" target='_blank' rel="nofollow noopener noreferrer">Wikitravel</a> has nice articles on most countries in the world, and you can learn a lot about culture, visa requirements, and living experience in different places.
        </>
      },
    ]
  },
  {
    article: 'coding',
    actionItems: [
      { id: 'coding-quiz',
        title: 'Answer the following questions:',
        description: <>
          <ul>
            <li>How to prepare for the coding interviews?</li>
            <li>Why you should solve some problems to practice algorithms, and some problems to practice coming up with solutions? What is the difference?</li>
            <li>What are mock interviews and why are they useful?</li>
          </ul>
        </>
      },
    ]
  },
  {
    article: 'language',
    actionItems: [
      { id: 'language-pick',
        title: "Pick the language you will be using.",
        description: <>
          Pick the language you will be using for the interviews. If unsure, pick one of Java, C++, or Python – whichever you know or like the best. You may also use some other language if you used it in the past a lot and are comfortable with it.
          <br/><br/>
          Sometimes it is fine to try using one language – say for a week – and then to switch to another one. But it is a good idea to eventually pick one after a week or two, and stick to it.
        </>
      },
      { id: 'language-styleguide',
        title: "Check out the style guide for your language.",
        description: <>
          Did you pick the language you will be using? If so, be sure to check out a <a href="#style-guide">style guide</a> for it. As an example of things you may look for, try to answer the following questions:
          <ul>
            <li>What is the naming convention? How to name variables, functions, and classes?</li>
            <li>What is the convention with comments, braces/parentheses, exceptions?</li>
          </ul>
          For diving deeper into the language features, you may also check out a good <a href="#getting-good-at-the-language">book</a> on it.
        </>
      },
    ]
  },
  {
    article: 'leetcode',
    actionItems: [
      { id: 'leetcode-features',
        title: "Explore Leetcode features.",
        description: <>
          Explore Leetcode features to know the website better and see what you will find useful.
          <ul>
            <li>Try solving some problems from the <a href="https://leetcode.com/problemset/top-interview-questions/" target='_blank' rel="nofollow noopener noreferrer">Top Interview Questions</a> list.</li>
            <li>
              Try doing a <a href="https://leetcode.com/interview/" target='_blank' rel="nofollow noopener noreferrer">Mock Interview</a>.
            </li>
            <li>
              Try participating (maybe virtually) in a <a href="https://leetcode.com/contest/" target='_blank' rel="nofollow noopener noreferrer">Leetcode contest</a>.
            </li>
          </ul>
        </>
      },
      { id: 'leetcode-solve',
        title: "Solve 200 Leetcode problems.",
        description: <>
          Solving problems on Leetcode is really important, and it is probably the best way to prepare for the coding interviews. 200 solved problems should give you a very good base.
          <br/><br/>
          This is a bit longer than a usual action item, so you may come back and mark it completed later, once you explore other sections of this website and solve enough problems.
        </>
      },
    ]
  },
  {
    article: 'codingtips',
    actionItems: [
      { id: 'codingtips-steps',
        title: "Write down the steps to follow during the coding interviews.",
        description: <>
          Write down the steps of how to approach the coding interviews. It is a good idea to print them or write them down on a piece of paper and put them next to your monitor so you can look at them during your interview to guide you and make sure you don't forget anything important.
          <br/><br/>
          You can use the following steps, or come up with your own:
          <ol>
            <li>
              Read or listen to the problem statement carefully.
            </li>
            <li>
              Ask clarifying questions and think of edge cases.
            </li>
            <li>
              Build some examples.
            </li>
            <li>
              Think about a straightforward solution.
            </li>
            <li>
              Think of and discuss the optimal solution.
            </li>
            <li>
              Think about what your code would look like before implementing it.
            </li>
            <li>
              Handle bad inputs and edge cases.
            </li>
            <li>
              Check the code after you are done. Think about how you would test it.
            </li>
            <li>
              See if anything can be improved.
            </li>
          </ol>
          You can also try to follow the same steps while practicing solving problems on your own.
        </>
      },
    ]
  },
  {
    article: 'mocks',
    actionItems: [
      { id: 'mocks-friends',
        title: "Organize a mock interview with a friend.",
        description: <>
          Do a mock interview (or maybe five) with a friend, and don't forget to interview him or her back!
          <br/><br/>
          Mocks interviews with friends are a nice and free way to practice passing coding interviews. Try to get the experience as close to the real interview as possible: speak English, have a set time limit (somewhere between 45 to 60 minutes), use <a href="https://docs.google.com" target='_blank' rel="nofollow noopener noreferrer">Google Docs</a>, <a href="https://coderpad.io/" target='_blank' rel="nofollow noopener noreferrer">coderpad.io</a>, or a whiteboard.
        </>
      },
      { id: 'mocks-pramp',
        title: "Try a mock interview on Pramp.",
        description: <>
          <a href="https://www.pramp.com/" target='_blank' rel="nofollow noopener noreferrer">Pramp</a> offers free online mock interviews with peers, and it is a great opportunity to practice speaking and solving problems with strangers. Try one or two mock interviews there, and see how you like it.
        </>
      },
    ]
  },
  {
    article: 'algos',
    actionItems: [
      { id: 'algos-checklist',
        title: "Create a personal checklist for learning algorithms.",
        description: <>
          Create a personal checklist for algorithms and techniques that you need to learn (see the <Link to="/algos/#algorithms-and-techniques-you-need-to-know">table above</Link> for essential coding interview algorithms). Your checklist can be on paper or in your favorite checklist app. Later, when studying algorithms, you can check off the ones that you have already learned, and see what's left for you.
        </>
      },
    ]
  },
  {
    article: 'timecomplexity',
    actionItems: [
      { id: 'timecomplexity-test',
        title: "Answer the following questions.",
        description: <>
          <ul>
            <li>What is the time complexity of an algorithm and why do we need it?</li>
            <li>For an algorithm that we say is running in O(N^2) time, what does O(N^2) really mean?</li>
            <li>When talking about time complexity, why do we only take the highest degree of the time complexity polynomial and ignore constants? Why two algorithms doing 3N^2 + 18N + 1235 and (1/2)N^2 operations respectively are said to both have O(N^2) time complexity?</li>
          </ul>
        </>
      },
      { id: 'timecomplexity-quiz',
        title: "Complete the quiz above.",
        description: <>
          Do the time complexity <Link to="/timecomplexity#quiz">Quiz</Link> above. How many questions you got correct?
        </>
      },
    ]
  },
  {
    article: 'recursion',
    actionItems: [
      { id: 'recursion-quiz',
        title: "What is recursion and why do we need it?",
      },
      { id: 'recursion-code',
        title: "What does the following code do, and what is its output?",
        description: <>
          <pre><code>
{`void f(final int N, String currentString) {
  if (currentString.length() == N) {
    System.out.println(currentString);
    return;
  }

  f(N, currentString + "0");
  f(N, currentString + "1");
}

f(3, "");
`}
          </code></pre>
        </>
      },
    ]
  },
  {
    article: 'graphs',
    actionItems: [
      { id: 'graphs-quiz',
        title: "Answer the following questions.",
        description: <>
          <ul>
            <li>What is a graph? What are edges and vertices of the graph?</li>
            <li>Where can graphs be used? Try to come up with at least four examples of real-life situations that can be modeled as a graph.</li>
            <li>How can we represent a graph in a computer program?</li>
          </ul>
        </>
      },
      { id: 'graphs-matrix',
        title: "Draw the graph that can be represented with the following adjacency matrix:",
        description: <>
          <pre><code>
0	0	1	0	0 <br/>
0	0	1	1	1 <br/>
1	1	0	0	0 <br/>
0	1	0	0	1 <br/>
0	1	0	1	0 <br/>
          </code></pre>
        </>
      },
    ]
  },
  {
    article: 'behavioural',
    actionItems: [
      { id: 'behavioural-questions',
        title: "Prepare your answers to possible behavioural interview questions.",
        description: <>
          Preparing answers for the questions you may be asked is one of the best ways to prepare for the behavioural interviews. Prepare your answers to the 15 common questions <Link to="/behavioural/#prepare-answers-to-possible-questions-you-may-be-asked">listed above</Link>.
        </>
      },
      { id: 'behavioural-experience',
        title: "Review and reflect on your past experience.",
        description: <>
          Review the past experience listed in your resume. This includes all your previous jobs, positions, and personal projects.
          <br/><br/>
          For each job, position, and project answer the following questions:
          <ul>
            <li>What were you working on? What did you accomplish?</li>
            <li>What did you learn?</li>
            <li>What were the challenges and difficulties?</li>
            <li>What did you enjoy the most?</li>
            <li>What were some mistakes or failures, and what would you do differently?</li>
            <li>How did you work with other people? What went well, and what didn't?</li>
          </ul>
        </>
      },
    ]
  },
  {
    article: 'systemdesign',
    actionItems: [
      { id: 'systemdesign-problems',
        title: "Solve five system design problems.",
        description: <>
          Find five system design problems, try to solve them yourself first, and then read the solutions.
          You can use the resources <Link to="/systemdesign#system-design-problems">listed above</Link> to find system design problems with the solutions.
          <br/><br/>
          To effectively prepare for the system design interviews, you will need to solve more than five problems, but solving just five is a nice way to get started.
        </>
      },
      { id: 'systemdesign-concepts',
        title: "Make sure you know important system design concepts.",
        description: <>
          See the <Link to="/systemdesign#system-design-concepts">list of common system design concepts</Link>, and make sure you know each of them and understand how they are used and why they are needed. These concepts are used everywhere in the software engineering, and are very important!
        </>
      },
      { id: 'systemdesign-steps',
        title: "Write down the steps of how to approach system design problems.",
        description: <>
          It can be a good idea to print them or write them down on a piece of paper – they can serve as a cheatsheet so you can look at them during your practice to guide you and make sure you don't forget anything important. You can also memorize them to use them as pointers on your actual interview.
          <br/><br/>
          You can use the <Link to="/systemdesign#system-design-problem-solving-approach">steps discussed above</Link>, or come up with your own. Writing down just a short name of each step is enough. For example, your list can look as follows:
          <ol>
            <li>Ask questions and specify design functionality.</li>
            <li>Scope business requirements.</li>
            <li>Do design estimations.</li>
            <li>Start with a simple design.</li>
            <li>Scale up and iterate.</li>
            <li>Think about details like testing and security.</li>
          </ol>
        </>
      },
    ]
  },
  {
    article: 'compensation',
    actionItems: [
      { id: 'compensation-quiz',
        title: "Answer the following questions:",
        description: <>
          <ul>
            <li>What are the base salary and stocks?</li>
            <li>What are stocks and stock options? What is the difference?</li>
            <li>What is the difference between a private and a public company? What is IPO?</li>
          </ul>
        </>
      },
      { id: 'compensation-research',
        title: "Research typical compensation in the companies and countries you are interested in.",
        description: <>
          If you already know what companies and countries you would be interested to work in, research the typical amount of compensation you may get. Take into account your experience level and country taxes. You can use the resources <Link to="/compensation#read-more">listed above</Link>.
        </>
      },
    ]
  },
  {
    article: 'negotiation',
    actionItems: [
      { id: 'negotiation-compensation',
        title: "Understand compensation.",
        description: <>
          Before starting a negotiation, it is <i>very</i> important to understand compensation first – what it consists of and what is the typical salary range for your level of experience in different companies. Be sure to read <Link to="/compensation">Compensation</Link> section and research typical compensation numbers in the companies you are applying to.
        </>
      },
      { id: 'negotiation-hiringcost',
        title: "Calculate how much it costs to hire a software engineer.",
        description: <>
          A good exercise before negotiating is to approximate how much it costs to the companies to hire a software engineer. Try to do your own calculation, and include the number of hours spent by different people to read resumes, conduct interviews, make decisions, and so on.
          <br/><br/>
          After you get your number, compare it with the calculations done by other people, for example, <a href="https://blog.usejournal.com/it-costs-50k-to-hire-a-software-engineer-d06a0d051abf" target='_blank' rel="nofollow noopener noreferrer">here</a>, <a href="https://www.celerative.com/blog/cost-to-hire-software-engineer" target='_blank' rel="nofollow noopener noreferrer">here</a>, or <a href="https://www.qualified.io/blog/posts/the-hidden-cost-of-hiring-software-engineers" target='_blank' rel="nofollow noopener noreferrer">here</a>. Realizing that hiring engineers is very expensive makes you understand that companies want to hire you as much as you want to get hired.
        </>
      },
    ]
  },
  {
    article: 'books',
    actionItems: [
      { id: 'books-start',
        title: "Start reading some software engineering book.",
        description: <>
          Pick a book that you like from the list above (or any other good software engineering book), and start reading!
          <br/><br/>
          Books are long and methodical – learning from them is different from any other way of learning. Don't miss out on this opportunity!
        </>
      },
    ]
  },
  {
    article: 'english',
    actionItems: [
      { id: 'english-test',
        title: "Test your knowledge of English.",
        description: <>
          Often, your English may be worse (or better) than you think, so it is a good idea to test it. For example, you can take online tests from <a href="https://learnenglish.britishcouncil.org/online-english-level-test" target='_blank' rel="nofollow noopener noreferrer">British Council</a> or <a href="https://www.cambridgeenglish.org/test-your-english/" target='_blank' rel="nofollow noopener noreferrer">Cambridge English</a> to assess your level. For interviews, it is good to have an Upper-Intermediate level or higher.
        </>
      },
      { id: 'english-switch',
        title: "Switch your phone and computer to English.",
        description: <>
          An easy way to help yourself get better in English is to surround yourself with it. Try switching to English things you use daily, like your phone and computer. To go even further, start watching movies and videos in English too (maybe with subtitles in your native language) – you won't notice how you will pick up more and more of the language in the background!
        </>
      },
    ]
  },
]
