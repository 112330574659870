export default [
  {
    name: '',
    title: '',
    articles: [
      { name: 'intro', title: 'Introduction' },
    ]
  },
  {
    name: 'preparing',
    title: 'Preparing for interviews',
    articles: [
      { name: 'format', title: 'Interview Format' },
      { name: 'types', title: 'Interview Types' },
      { name: 'preparation', title: 'How to Prepare' },
      { name: 'timeline', title: 'Timeline' },
      { name: 'future', title: 'Preparing for the Future' },
      { name: 'motivation', title: 'Motivation' },
    ]
  },
  {
    name: 'applying',
    title: 'Applying to companies',
    articles: [
      { name: 'applying', title: 'Applying to Companies' },
      { name: 'resume', title: 'Resume' },
      { name: 'immigration', title: 'Visas and Immigration' }
    ]
  },
  {
    name: 'coding',
    title: 'Coding interviews',
    articles: [
      { name: 'coding', title: 'Intro to Coding Interviews' },
      { name: 'language', title: 'Picking a Language' },
      { name: 'leetcode', title: 'Leetcode' },
      { name: 'codingtips', title: 'Coding Interview Tips' },
      { name: 'mocks', title: 'Mock Interviews' },
    ]
  },
  {
    name: 'algorithms',
    title: 'Algorithms',
    articles: [
      { name: 'algos', title: 'How to Learn Algorithms' },
      { name: 'simple', title: 'Simple Problems' },
      { name: 'loops', title: 'Loops' },
      { name: 'arrays', title: 'Arrays' },
      { name: 'timecomplexity', title: 'Time Complexity' },
      { name: 'sorting', title: 'Sorting' },
      { name: 'setsmaps', title: 'Sets and Maps' },
      { name: 'stack', title: 'Stack' },
      { name: 'linkedlist', title: 'Linked List' },
      { name: 'priorityqueue', title: 'Priority Queue' },
      { name: 'strings', title: 'Strings' },
      { name: 'twopointers', title: 'Two Pointers' },
      { name: 'recursion', title: 'Recursion' },
      { name: 'backtracking', title: 'Backtracking' },
      { name: 'math', title: 'Math' },
      { name: 'binarysearch', title: 'Binary Search' },
      { name: 'bits', title: 'Bit Operations' },
      { name: 'graphs', title: 'Graphs' },
      { name: 'dfs', title: 'Depth-first Search' },
      { name: 'bfs', title: 'Breadth-first Search' },
      { name: 'binarytrees', title: 'Binary Trees' },
      { name: 'dp', title: 'Dynamic Programming' },
      { name: 'trie', title: 'Trie' },
    ]
  },
  {
    name: 'behavioural',
    title: 'Behavioural interviews',
    articles: [
      { name: 'behavioural', title: 'Behavioural Interviews' },
    ]
  },
  {
    name: 'systemdesign',
    title: 'System Design Interviews',
    articles: [
      { name: 'systemdesign', title: 'System Design Interviews' },
    ]
  },
  {
    name: 'afterinterviews',
    title: 'After the interviews',
    articles: [
      { name: 'compensation', title: 'Compensation' },
      { name: 'negotiation', title: 'Negotiation' }
    ]
  },
  {
    name: 'misc',
    title: 'Misc',
    articles: [
      { name: 'books', title: 'Books' },
      { name: 'english', title: 'English' }
    ]
  },
]