import React from 'react';
import { Link } from "gatsby"
import topics from "../topics/topics.js";
import TopicsManager from "../topics/topicsManager.js";
import FirebaseManager from '../firebase/firebaseManager.js';
import actionItemsList from '../actionItems/actionItems.js';
import articleProblemsList from '../articleProblems/articleProblems.js';
import "./ArticlesSidebar.css"

class ArticlesSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.scrollToLink = React.createRef();
    this.state = {
      completedArticles: this.getCompletedArticles(),
    };
  }

  progressDataUpdated = () => {
    this.setState({
      completedArticles: this.getCompletedArticles()
    });
  }

  componentDidMount() {
    this.scrollToSelected();
    FirebaseManager.subscribe(this.progressDataUpdated);
  }

  componentWillUnmount() {
    FirebaseManager.unsubscribe(this.progressDataUpdated);
  }

  getCompletedArticles() {
    return TopicsManager.getAllArticles().filter((article) => {
      let actionItems = actionItemsList
        .find((articleItems) => articleItems.article === article.name)?.actionItems || [];
      let articleProblems = articleProblemsList
        .find((articleProblems) => articleProblems.article === article.name)?.problems || [];
      
      const actionItemsNum = actionItems.length;
      const articleProblemsNum = articleProblems.length;
      if (actionItemsNum + articleProblemsNum === 0)
        return false;

      return actionItems.filter((item) => FirebaseManager.getCompletedActionItems().indexOf(item.id) > -1).length + 
             articleProblems.filter((problem) => FirebaseManager.getCompletedArticleProblems().indexOf(problem) > -1).length 
             === actionItemsNum + articleProblemsNum;
    });
  }

  scrollToSelected() {
    if (this.scrollToLink && this.scrollToLink.current) {
      this.scrollToLink.current.scrollIntoView({block: "center"});
    }
  }

  toggleOpen = () => {
    this.props.onToggle();
  }

  render() {
    const selectedArticle = this.props.selectedArticle;

    return (
      <div className="bg-white pb-24 md:border-r md:border-gray-200">
        {this.props.onToggle && 
          <div onClick={this.toggleOpen} className={"mr-8 float-right cursor-pointer -mt-6 mb-4"}>
            <svg style={{bottom: 5}} className="fixed z-10 p-1 rounded-full hover:bg-gray-100 h-8 w-8 text-gray-300 hover:text-gray-400 transition duration-100 ease-in-out" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd"></path>
            </svg>
          </div>
        }
        {
          topics.map(topic => (
              <div key={topic.title}>
                {topic.title && 
                  <h2 className="font-semibold text-gray-800 mt-6 mb-4">
                    {topic.title}
                  </h2>
                }
                <div className="bullets flex flex-col">
                  {
                    topic.articles.map(article => (
                      <Link to={'/' + article.name}
                            key={article.name}
                            onClick={this.props.onSelect} 
                            className={(this.state.completedArticles.indexOf(article) > -1 ? 'bullet-done' : '') + ' bullet text-gray-700 pt-2 pb-2 rounded-md pr-8 mr-2 pl-8 transition easy-in-out duration-100' + (selectedArticle === article.name ? ' bg-blue-100 font-medium' : ' hover:bg-gray-100')} 
                            ref={selectedArticle === article.name ? this.scrollToLink : null}>
                        {article.title}
                      </Link>
                    ))
                  }
                </div>
              </div>
            )
          )
        }
      </div>
    );
  }
}

export default ArticlesSidebar;