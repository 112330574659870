import topics from './topics.js'

class TopicsManager {
  static getAllArticles() {
    let articles = [];
    topics.forEach(topic => {
      topic.articles.forEach(article => {
        articles.push(article);
      })
    });
    return articles;
  }

  static getNextArticle(articleName) {
    let articles = this.getAllArticles();
    for (let i = 0; i + 1 < articles.length; i++) {
      if (articles[i].name === articleName) {
        return articles[i + 1];
      }
    }
    return null;
  }
}

export default TopicsManager;
