import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, selectedArticle }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    // style={{backgroundColor: '#F7FAFC'}}
    <div >
      <Header selectedArticle={selectedArticle} siteTitle={data.site.siteMetadata.title} />
      <div className="mt-16 pt-6 px-4">
        <main className="py-6">{children}</main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
